// src/pages/writing/loose-leaves/she-devil.js
import React from 'react';
import withBlogSEO from '../../../utils/withBlogSEO';

function SheDevil() {
  return (
    <div className="max-w-2xl mx-auto px-4 py-8 prose prose-sm">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">the life and loves of a she-devil: chapter 36</h1>
      <div className="text-gray-600">
        <p className="italic text-sm mb-4">[4/2018]</p>
        
        <div className="mb-8 italic">
          <p className="mb-4">
            <span className="not-italic">The Life and Loves of a She-Devil</span> is a 1983 novel by British feminist author Fay Weldon that uses Greek-tragic elements to describe an "ugly" woman getting revenge on her cheating husband.
          </p>
          
          <p className="mb-4">
            I wrote a subsequent chapter – Chapter 36 – to the ultimate one of Weldon's (fantastic) novel. This was part of a larger project which prescribed tragic women the (fictional) chance to speak about their emotions.
          </p>
          
          <p className="mb-8">
            Very interestingly, this has been, <em>by far</em>, the most popular page on this website.
          </p>
        </div>

        <hr className="my-8" />
        
        <div className="whitespace-pre-line text-base">
        <p className="mb-4">
            I no longer live in the High Tower. Something about the sea being too loud is what I told Bobbo. I had Garcia to stop coming to clean the windows even before I decided to leave. I said something about them always getting dirty again, and how it was tiring for me to see him do it over every week. Garcia still came to the bedroom when I asked, though, but only when Bobbo was around.
          </p>
          
          <p className="mb-4">
            Should he still be alive, Bobbo would lay his frail body on the bed, when I would let him, far enough away from me so his scent would not ruin whatever fragrance I allowed rest on my body. Bobbo could taste the window cleaner on the bed. I had Garcia bring it even when he didn't clean. I knew Bobbo would remember those moments better.
          </p>
          
          <p className="mb-4">
            For weeks in a row, Bobbo tirelessly worked in the garden outside. I let him be. He usually went when I was busy with Garcia or some other man who was taller or with a more chiseled visage. Sometimes I would make Bobbo stay, but eventually just the thought of him knowing I was with a lover sufficed. Poor boy! Each of them: one a tool, the other a lackey.
          </p>
          
          <p className="mb-4">
            Good, I thought, when Bobbo would get upset. The more time he spent in the garden, the less he would care for my men. Admittedly, I cared less as time passed, too. On sunny days Bobbo spent his time outside in the garden, catering to lilacs and tulips and other flowers with temporary lives and reliant bodies. And on the cold days, Bobbo would sit inside and stare out, often letting in men to go upstairs and Bobbo would lie just there unless I called him up to watch.
          </p>
          
          <p className="mb-4">
            Anyway, I noticed Bobbo being less miserable. It had been years, yes, since that girl passed. Mary Fisher. He didn't forget about her; how could he? He didn't mention her, his lying self. He smiled to himself more often. Of course, the scent of spotless windows still would steal a frown on Bobbo's cheek. But those looks of terror became more sparing as time went on.
          </p>
          
          <p className="mb-4">
            Some days he would bring in dandelions and try to hide them where he figured I couldn't reach. Poor boy! I have men, he knew that. They would be gone within hours of my smelling them.
          </p>
          
          <p className="mb-4">
            I didn't quite mind finding them. They were usually yellow, a color that looked good on me and within the High Tower. Sometimes the pink tulips were kind surprises to the eye, before I had to make Bobbo miserable once more and take them away.
          </p>
          
          <p className="mb-4">
            One day, I decided to move out. No reason in particular, but that I just wanted a change of scenery. I had attached too much to this wretched house. I had Garcia dump his window cleaner in the garden. During intercourse was only right.
          </p>
          
          <p className="mb-4">
            The next day, Bobbo was out in the field, lying down forever. Dead, finally. I shed no tears, of course, but there was an empty feeling of having nothing to prove. Alas, justice has been served.
          </p>
          
          <p className="mb-4">
            I bought No. 19 Nightbird Drive, out in Eden Grove. A man named Amnon helped move me in. He seemed nice.
          </p>
        </div>
      </div>
    </div>
  );
}

export default withBlogSEO(SheDevil, {
  title: "the life and loves of a she-devil: chapter 36",
  type: "blog",
});