import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const sendPageView = () => {
      // Remove the leading slash and hash for cleaner reporting
      const cleanPath = location.pathname.replace(/^\//, '');
      const pageTitle = document.title || cleanPath;
      
      // Send pageview to Google Analytics
      if (window.gtag) {
        window.gtag('config', 'G-B60ERD1VQ4', {
          page_path: location.pathname,
          page_title: pageTitle
        });
      }
    };

    sendPageView();
  }, [location]);

  return null;
};

export default GoogleAnalytics;