// src/data/quotes.js
export const quotes = [
  {
    quote: "We made love as the sun beats, as the coffins close",
    author: "André Breton"
  },
  {
    quote: "I discovered what people are capable of, in other words, anything: sublime or deadly desires, lack of dignity, attitudes and beliefs I had found absurd in others until I myself turned to them. Without knowing it, he brought me closer to the world.",
    author: "Annie Ernaux"
  },
  {
    quote: "When I was a child, luxury was fur coats, evening dresses, and villas by the sea. Later on, I thought it meant leading the life of an intellectual. Now I feel that it is also being able to live out a passion for a man or a woman.",
    author: "Annie Ernaux"
  },
  {
    quote: "I began to realize that the only places where things were actually real was at this frontier between what you think is you and what you think is not you.",
    author: "David Whyte"
  },
  {
    quote: "My greatest regret was how much I believed in the future.",
    author: "Jonathan Safran Foer"
  },
  {
    quote: "I write entirely to find out what I'm thinking.",
    author: "Joan Didion"
  },
  {
    quote: "In order for me to write poetry that isn't political, I must listen to the birds, and in order to hear the birds, the warplanes must be silent.",
    author: "Marwan Markhoul"
  },
  {
    quote: "Of course, you could just take off the blindfold and say, 'I think this game is stupid and I'm not playing it anymore.' And it must also be admitted that hitting the wall or wandering off in the wrong direction or tearing off the blindfold is as much a part of the game as is pinning the tail on the donkey.",
    author: "Maggie Nelson"
  },
  {
    quote: "What we gained in intensity we lost in time.",
    author: "Annie Ernaux"
  },
  {
    quote: "It occurred to me that writing should aim to do the same, to replicate the feeling of witnessing sexual intercourse, that feeling of anxiety and stupefaction, a suspension of moral judgment.",
    author: "Annie Ernaux"
  },
  {
    quote: "For to wish to forget how much you loved someone––and then, to actually forget––can feel, at times, like the slaughter of a beautiful bird who chose, by nothing short of grace, to make a habitat of your heart. I have heard that this pain can be converted, as it were, by accepting 'the fundamental impermanence of all things.' This acceptance bewilders me: it seems like an act of will; at others, of surrender. Often I feel myself to be rocking between them (seasickness).",
    author: "Maggie Nelson"
  },
  {
    quote: "If you took the monsters' point of view, everything they did made perfect sense. The trick was learning to think like a monster.",
    author: "Sy Montgomery"
  },
  {
    quote: "The appreciation of deception happens at the moment when the deception is undone, or by the imaginative creation of a less sophisticated reader who has not seen through the deceit.",
    author: "Allison Sharrock, \"The Art of Deceit\""
  },
  {
    quote: "And nonetheless, my love, you have remained a shramana, nonetheless you do not love me, you love no human being. Is this not so?",
    author: "Herman Hesse"
  },
  {
    quote: "Rationality of thought imposes a limit on a person's concept of his relation to the cosmos. For example, a non-Zoroastrian could think of Zarathustra as simply a madman who led millions of naive followers to adopt a cult of ritual fire worship. But without his 'madness' Zarathustra would necessarily have been only another of the millions or billions of human individuals who have lived and then been forgotten.",
    author: "John Nash, Nobel Prize acceptance speech, 1994"
  },
  {
    quote: "But as I watched him smile back at me and zip his coat, I saw everything in the world build up and then everything in the world fall down again.",
    author: "Marina Keegan"
  },
  {
    quote: "In the United States if you ask someone if they play chess and they say 'no,' it's because they don't know how the horsey moves. In Russia, if you ask someone if they play chess and they say 'no,' it's because they're only 1600.",
    author: "chess.com user \"Loomis\""
  },
  {
    quote: "For to wish to forget how much you loved someone––and then, to actually forget––can feel, at times, like the slaughter of a beautiful bird who chose, by nothing short of grace, to make a habitat of your heart. I have heard that this pain can be converted, as it were, by accepting 'the fundamental impermanence of all things.' This acceptance bewilders me: it seems like an act of will; at others, of surrender. Often I feel myself to be rocking between them (seasickness).",
    author: "Maggie Nelson"
  },
  {
    quote: "All is well, the old man is dancing.",
    author: "Unknown (Roman lore)"
  },
  {
    quote: "Sydney Afriat is also a connoisseur of the art of good writing. He may well be the last remaining stylist writing exquisite economic prose with a liberal use of the subjunctive, a grammatical form long in decline in the Anglo-Saxon world (but much in evidence, even in daily discourse, of the language spoken in the Siena in which he lives). Moreover, he may belong to another select group of prose stylists who are also masters of some aspects of the mathematical method and its philosophy.",
    author: "Kumaraswamy Velupillai"
  },
  {
    quote: "A lot of people have said that if Tal had looked after his health, if he hadn't led such a dissolute life... and so forth. But with people like Tal, the idea of 'if only' is just absurd. He wouldn't have been Tal then.",
    author: "Salli Landau, Chess Week (Russia), 2003"
  },
  {
    quote: "I envy you going to Oxford: it is the most flower-like time of one's life. One sees the shadow of things in silver mirrors. Later on, one sees the Gorgon's head, and one suffers, because it does not turn one to stone.",
    author: "Oscar Wilde, in a letter to Louis Wilkinson, 1898"
  },
  {
    quote: "In addition, Dr. Dannyboy has suggested a fifth element: positive thought. Pointing out that their breathing, bathing, dining, and screwing brought Alobar and Kudra much physical pleasure, and that an organism steeped in pleasure is an organism disposed to continue, he has said that the will to live cannot be overestimated as a stimulant to longevity. Indeed, Dr. Dannyboy goes so far as to claim that ninety percent of all deaths are suicides.",
    author: "Tom Robbins"
  },
  {
    quote: "If you took the monsters' point of view, everything they did made perfect sense. The trick was learning to think like a monster.",
    author: "Sy Montgomery"
  },
  {
    quote: "He totally can, but refuses to draw a straight line.",
    author: "Thumpmonks, on the game developer Mateusz Skutnik, 2018"
  },
  {
    quote: "The fire of this kiss was still around him like a colored glory when he returned home. He could not lay it aside in the hall as he did this black felt hat, and when he came into the bedroom he thought that his wife must see that halo.",
    author: "Vladimir Nabokov"
  },
  {
    quote: "Nothing is as practical as a good theory.",
    author: "Kurt Lewin, Field theory in social science: Selected theoretical papers, 1952"
  },
  {
    quote: "Early in the novel that Tereza clutched under her arm when she went to visit Tomas, Anna meets Vronsky in curious circumstances: they are at the railway station when someone is run over by a train. At the end of the novel, Anna throws herself under a train. This symmetrical composition––the same motif appears at the beginning and at the end––may seem quite 'novelistic' to you, and I am willing to agree, but only on condition that you refrain from reading such notions as 'fictive,' 'fabricated,' and 'untrue to life' into the word 'novelistic.' Because human lives are composed in precisely such a fashion.",
    author: "Milan Kundera"
  },
  {
    quote: "I know it's a kick in the pants to know the problem is you, but it's also fucking fantastic. You are, after all, the only person you can change.",
    author: "Cheryl Strayed, \"The Rumpus Advice Column #38: Romantic Love is Not a Competitive Sport\""
  },
  {
    quote: "Lord, I confess I want the clarity of catastrophe but not the catastrophe. Like everyone else, I want a storm I can dance in. I want an excuse to change my life.",
    author: "Franny Choi, \"Catastrophe Is Next to Godliness\""
  },
  {
    quote: "Who was left? No one, I thought, and someday these streets, where my grandmother drank hot tea from an old jahrzeit glass, would be empty and we would all of us have moved to the crest of the Orange Mountains, and wouldn't the dead stop kicking at the slats in their coffins then?",
    author: "Philip Roth"
  },
  {
    quote: "And presently I was driving through the drizzle of the dying day, with the windshield wipers in full action but unable to cope with my tears.",
    author: "Vladimir Nabokov"
  },
  {
    quote: "I want pure colors, melting clouds, accurately drawn details, a sunburst above a receding road with the light reflected in furrows and ruts, after rain. And no girls.",
    author: "Vladimir Nabokov, on the cover of Lolita"
  },
  {
    quote: "Beware of summer lovin'.",
    author: "Lazerek Perry"
  },
  {
    quote: "It is completely unimportant. That is why it is so interesting.",
    author: "Agatha Christie"
  },
  {
    quote: "I wondered if that was how forgiveness budded; not with the fanfare of epiphany, but with pain gathering its things, packing up, and slipping away unannounced in the middle of the night.",
    author: "Khaled Hosseini"
  },
  {
    quote: "The best meditations move at breakneck pace, the second best are completely stationary. Aurelius was a master horseman, but know that it was the horse that moved and not the emperor.",
    author: "Kimathi Muiruri, \"so now You have seen free men\""
  },
  {
    quote: "The #1 programmer excuse for legitimately slacking off: 'my code's compiling!'",
    author: "xkcd"
  },
  {
    quote: "About dreams. It is usually taken for granted that you dream of something that has made a particularly strong impression on you during the day, but it seems to me it's just the contrary. Often it's something you paid no attention to at the time — a vague thought that you didn't bother to think out to the end, words spoken without feeling and which passed unnoticed — these are the things that return at night, clothed in flesh and blood, and they become the subjects of dreams, as if to make up for having been ignored during waking hours.",
    author: "Boris Pasternak"
  },
  {
    quote: "Everything is like something else. / I should have waited before I learned this.",
    author: "John Ashbery, \"No Longer Very Clear\""
  },
  {
    quote: "If we were not something more than unique human beings, if each one of us could really be done away with once and for all by a single bullet, storytelling would lose all purpose. But every man is more than just himself; he also represents the unique, the very special and always significant and remarkable point at which the world's phenomena intersect, only once in this way and never again.",
    author: "Herman Hesse"
  },
  {
    quote: "Last year, in October, it was me who was starting a love affair, whose excruciating outcome was naturally to be expected. But going to the ends of misery first means going to the ends of happiness. Yesterday, it came to me with certainty that I write my love stories and live my books, in a perpetual round dance.",
    author: "Annie Ernaux"
  },
  {
    quote: "I stopped watching for ridicule, the scorpion's tail hidden in his words. He said what he meant; he was puzzled if you did not. Some people might have mistaken this for simplicity. But is it not a sort of genius to cut always to the heart?",
    author: "Madeline Miller"
  },
  {
    quote: "You think your pain and your heartbreak are unprecedented in the history of the world, but then you read.",
    author: "James Baldwin, LIFE magazine interview, 1963"
  },
  {
    quote: "What's the point of being in love if I can't be clever?",
    author: "Plautus, Casina"
  },
  {
    quote: "At the end of my suffering / there was a door.",
    author: "Louise Glück"
  },
  {
    quote: "Dr. Dannyboy goes so far as to claim that ninety percent of all deaths are suicides.",
    author: "Tom Robbins"
  },
  {
    quote: "The beet was Rasputin's favorite vegetable. You could see it in his eyes.",
    author: "Tom Robbins"
  },
  {
    quote: "Of our nine planets, Saturn is the one that looks like fun. Of our trees, the palm is obviously the stand-up comedian. Among fowl, the jester's cap is worn by the duck. Of our fruits and vegetables, the tomato could play Falstaff, the banana a more slapstick role. As Hamlet-or Macbeth-the beet is cast. In largely vegetarian India, the beet is rarely eaten because its color is suggestive of blood. Out, damned mangel-wurzel. Alobar was remembering. . . .",
    author: "Tom Robbins"
  },
  {
    quote: "And now he was deriving a morbid pleasure from feeling hurt.",
    author: "Vladimir Nabokov"
  },
  {
    quote: "A good answer must be reinvented many times, from scratch.",
    author: "Richard Powers"
  },
  {
    quote: "I don't know who sold our homeland. But I saw who paid the price.",
    author: "Mahmoud Darwish"
  },
  {
    quote: "I've been a forest burning no direction\nI tried to hold you, I tried everything but running\nMy heart is panicking are you kissing him?\nAre you reaching through all your days with him?",
    author: "Blind Pilot"
  },
  {
    quote: "Genuine poetry can communicate before it is understood.",
    author: "T.S. Eliot, on Dante"
  },
  {
    quote: "In real love, real union, or communion, there are no rules",
    author: "bell hooks"
  },
  {
    quote: "How odd I can have all this inside me and to you it's just words.",
    author: "David Foster Wallace"
  },
  {
    quote: "From the very beginning, and throughout the whole of our affair, I had the privilege of knowing what we all find out in the end: the man we love is a complete stranger.",
    author: "Annie Ernaux"
  }

  
];